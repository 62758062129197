import React, { useEffect, useState } from "react";

export const Counter = ({ percentage, label }) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [_percentage, setPercentage] = useState(0);

  useEffect(() => {
    let per = percentage;

    if (per > 100) {
      per = 100;
    }
    setX(200 + 100 * Math.cos((((per * 180) / 100 - 180) * Math.PI) / 180.0));
    setY(200 + 100 * Math.sin((((per * 180) / 100 - 180) * Math.PI) / 180.0));

    setPercentage(per.toFixed(0));
  }, [percentage]);

  return (
    <div className="absolute top-1/2 flex h-full h-80 w-full -translate-y-44/100 transform flex-col items-center justify-center">
      <svg
        className="text-center"
        strokeWidth="20"
        fill="transparent"
        width="100%"
        height="100%"
        viewBox="0 0 400 400"
      >
        <path
          strokeLinecap="round"
          stroke="currentColor"
          className="text-white opacity-30"
          d="M 100 200 A 100 100 0 0 1 300 200"
        />
        <path
          strokeLinecap="round"
          stroke="currentColor"
          className="text-white"
          d={`M 100 200 A 100 100 0 0 ${_percentage === 0 ? 0 : 1} ${x} ${y}`}
        />
      </svg>
      <div className="absolute text-sm font-bold text-white sm:text-xl md:text-2xl lg:text-3xl">
        {label}
      </div>
    </div>
  );
};
