import { CounterSkeleton } from "./Indicators/CounterSkeleton";
import { ProductionInformationSkeleton } from "./ProductionInformationSkeleton";

export const ProductionDashboardSkeleton = () => {
  return (
    <>
      <div className={"animate-pulse"}>
        <div className="mb-4 space-y-4 md:grid md:grid-cols-3 md:gap-x-4 md:space-y-0 flex-initial">
          <div className="relative rounded-3xl bg-trames-orange h-48">
            <CounterSkeleton label="TRS" />
          </div>
          <div className="relative rounded-3xl bg-trames-green h-48">
            <CounterSkeleton label="rebuts" />
          </div>
          <div className="relative rounded-3xl bg-trames-blue h-48">
            <CounterSkeleton label="Durée OF min" />
          </div>
        </div>
        <div className="space-y-4 lg:flex lg:grid lg:grid-cols-3 lg:gap-x-4 lg:space-y-0 flex-1">
          <ProductionInformationSkeleton label="Temps de cycles" text={true} />
          <ProductionInformationSkeleton label="Arrêts" text={true} />
          <ProductionInformationSkeleton />
        </div>
      </div>
    </>
  );
};
