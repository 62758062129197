import React, { useContext, useEffect, useState } from "react";
import { eventContext, PAUSE, PRODUCTION } from "../../../context/eventContext";
import { Tab } from "@headlessui/react";
import { PauseLine } from "./Indicators/PauseLine";
import { ProductionLine } from "./Indicators/ProductionLine";
import {
  classNames,
  formatSecondDurationToTimeString,
} from "../../../utils/misc";
import { TrsCounter } from "./Indicators/TrsCounter";
import { CycleCounter } from "./Indicators/CycleCounter";
import { ItemCounter } from "./Indicators/ItemCounter";
import { DurationCounter } from "./Indicators/DurationCounter";
import { ProductionControl } from "./ProductionControl";
import { ChartBarIcon, ViewListIcon } from "@heroicons/react/outline";
import { PauseProgress } from "./Indicators/PauseProgress";
import { modalContext } from '../../../context/modalContext';
import { ContentCancelProduction } from './ContentCancelProduction';

export const ProductionDashboard = () => {
  const { events, pauseReasons, instruction, indicators, firstPlay, loading } =
    useContext(eventContext);
  const { setOpen, setContent } = useContext(modalContext);
  const [totalStopDuration, setTotalStopDuration] = useState(null);

  useEffect(() => {
    let totalPause = 0;
    if (events.length > 0) {
      events
        .filter((e) => e.type === PAUSE)
        .forEach((pause) => {
          totalPause += pause.duration;
        });
    }
    setTotalStopDuration(formatSecondDurationToTimeString(totalPause));
  }, [events.length]);

  const handleCancelProduction = () => {
    setOpen(true)
    setContent(<ContentCancelProduction />)
  }

  return (
    <>
      <div className="mb-4 space-y-4 md:grid md:grid-cols-3 md:gap-x-4 md:space-y-0 flex-initial">
        <div className="relative rounded-3xl bg-trames-orange h-48">
          {instruction && (
            <TrsCounter instruction={instruction} indicators={indicators} />
          )}
        </div>

        <div className="relative rounded-3xl bg-trames-green h-48">
          {instruction && (
            <ItemCounter instruction={instruction} indicators={indicators} />
          )}
        </div>

        <div className="relative rounded-3xl bg-trames-blue h-48">
          {instruction && (
            <DurationCounter
              firstPlay={firstPlay}
              instruction={instruction}
              indicators={indicators}
            />
          )}
        </div>
      </div>

      <div className="space-y-4 lg:flex lg:grid lg:grid-cols-3 lg:gap-x-4 lg:space-y-0 flex-1">
        <div className="flex flex-col">
          <div className="box-container flex-1">
            <div className="mb-6 text-gray-900">
              <div className="flex justify-between pb-2">
              <h2 className="box-title mb-2">Temps de cycles</h2>
                {events.filter((event) => event.type === PRODUCTION).length > 0 && (
                  <button
                    disabled={loading}
                    type="button"
                    className="z-10 inline-flex items-center px-2 py-2 border border-transparent text-sm rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
                    onClick={handleCancelProduction}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
                )}
              </div>
              {instruction && (
                <CycleCounter
                  instruction={instruction}
                  events={events.filter((event) => event.type === PRODUCTION)}
                />
              )}
            </div>

            <div className="h-[300px] flex-1 overflow-auto overscroll-auto">
              <div className="space-y-2">
                {events
                  .filter((e) => e.type === PRODUCTION)
                  .reverse()
                  .map((e, index) => (
                    <ProductionLine
                      key={index}
                      index={index}
                      event={e}
                      events={events}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="box-container flex-1">
            <Tab.Group>
              <div className="mb-6 text-gray-900">
                <div className="flex">
                  <h2 className="box-title mb-2 flex-1">Arrêts</h2>
                  <span className="relative z-0 inline-flex">
                    <Tab.List>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "bg-trames-orange text-white"
                              : "bg-trames-orange bg-opacity-20 text-trames-orange",
                            "relative inline-flex items-center justify-center rounded-l-md px-2 py-2 text-sm"
                          )
                        }
                      >
                        <ViewListIcon className="mr-2 h-4 w-4" />
                        <span>Détail</span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "bg-trames-orange text-white"
                              : "bg-trames-orange bg-opacity-20 text-trames-orange",
                            "relative inline-flex items-center justify-center rounded-r-md px-2 py-2 text-sm"
                          )
                        }
                      >
                        <ChartBarIcon className="mr-2 h-4 w-4" />
                        <span>Répartition</span>
                      </Tab>
                    </Tab.List>
                  </span>
                </div>
                {totalStopDuration && (
                  <span className="badge badge-primary-invert text-xs">
                    Total {totalStopDuration}
                  </span>
                )}
              </div>
              {pauseReasons.length > 0 &&
                <Tab.Panels>
                  <Tab.Panel className="h-[300px] overflow-auto overscroll-auto">
                    <div className="space-y-2">
                      {events
                        .filter((e) => e.type === PAUSE)
                        .reverse()
                        .map((e, index) => (
                          <PauseLine
                            key={index}
                            index={index}
                            event={e}
                            pauseReasons={pauseReasons}
                          />
                        ))}
                    </div>
                  </Tab.Panel>
                  <Tab.Panel className="h-[278px] overflow-auto overscroll-auto">
                    <PauseProgress
                      events={events.filter((e) => e.type === PAUSE)}
                      pauseReasons={pauseReasons}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              }
            </Tab.Group>
          </div>
        </div>
        <ProductionControl />
      </div>
    </>
  );
};
