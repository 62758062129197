import React from "react";
import { Counter } from "./Counter";

export const ItemCounter = ({ instruction, indicators }) => (
  <Counter
    percentage={(indicators.totalConform / instruction.performanceTarget) * 100}
    label={
      <div className="mt-6 text-center">
        <p className="text-xs uppercase">production de l'of</p>
        {indicators.totalConform}/{instruction.performanceTarget}
        <br />
        {indicators.totalDefault} rebuts
      </div>
    }
    time={false}
  />
);
