export const ProductionInformationSkeleton = ({ label, text }) => {
  return (
    <div className="flex flex-col">
      <div className="box-container flex-1">
        {label && (
          <div className="mb-6 text-gray-900">
            <h2 className="box-title mb-2">{label}</h2>
          </div>
        )}

        <div className="h-80 flex-1 overflow-auto overscroll-auto">
          <div className="space-y-2">
            {text && (
              <>
                {[...Array(10).keys()].map((index) => (
                  <div key={index} className="flex">
                    <div className="h-8 w-full rounded bg-gray-200" />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
