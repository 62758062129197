import React from "react";
import { formatSecondDurationToTimeString } from "../../../../utils/misc";
import { ClockIcon } from "@heroicons/react/outline";

export const PauseLine = ({ event, pauseReasons }) => {
  let time = formatSecondDurationToTimeString(event.duration);
  return (
    <div className="flex">
      <div className="text-gray-600">
        {new Date(event.createdAt).toLocaleTimeString()}
      </div>
      <div className="flex-1 px-4 font-bold">
        {event.properties &&
          event.properties.productionPauseReasons &&
          event.properties.productionPauseReasons.map(
            (value) =>
              `${
                pauseReasons.find((pauseReason) => pauseReason.id == value)
                  .label
              }${
                value !== event.properties.productionPauseReasons.slice(-1)[0]
                  ? " / "
                  : ""
              }`
          )}
      </div>
      <div className="badge badge-primary">
        <ClockIcon className="font-white mr-1 h-4 w-4" />
        {time && time}
      </div>
    </div>
  );
};
