import React, { useContext, useRef } from "react";
import { eventContext, PAUSE, PLAY } from "../../../context/eventContext";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { classNames } from "../../../utils/misc";

const TYPE_HAZARD = 0,
  TYPE_SCRAP = 1;

export const Pause = () => {
  const { events, addEvent, pauseReasons, setActive } =
    useContext(eventContext);
  const formRef = useRef();

  const schema = yup.object({
    pauseReason: yup.string().required("Merci de sélectionner une valeur"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    addEvent({
      type: PAUSE,
      createdAt: Date.now(),
      properties: { productionPauseReasons: [data.pauseReason] },
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setActive(PLAY);
  };

  return pauseReasons.length > 0 ? (
    <div className="mb-4 w-full rounded-2xl bg-white p-4">
      <form
        className="space-y-6"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
      >
        <PauseReasonsRadioGroup pauseReasons={pauseReasons} control={control} />
        {errors.pauseReason && (
          <div className="text-3xl text-red-600">
            {errors.pauseReason.message}
          </div>
        )}
        <div className="grid grid-cols-2 gap-4">
          <button
            type="button"
            className="btn-primary-invert btn-large"
            onClick={handleCancel}
          >
            Annuler
          </button>
          <button type="submit" className="btn-primary btn-large">
            Valider
          </button>
        </div>
      </form>
    </div>
  ) : (
    "chargement"
  );
};

const PauseReasonsRadioGroup = ({ pauseReasons, control }) => (
  <Controller
    control={control}
    name="pauseReason"
    render={({ field: { onChange, onBlur, value } }) => (
      <RadioGroup value={value} onChange={onChange}>
        <div className="grid grid-cols-2 divide-x-2 divide-trames-orange divide-dotted">
          <div className="space-y-4 pr-4">
            <h2 className="text-xl font-bold text-gray-900">Aléas</h2>
            <div className="grid grid-cols-2 gap-4">
            {pauseReasons
              .filter((value) => value.type === TYPE_HAZARD)
              .map((pauseReason) => (
                <PauseReasonRadio
                  key={pauseReason.id}
                  pauseReason={pauseReason}
                />
              ))}
            </div>
          </div>
          <div className="space-y-4 pl-4">
            <h2 className="text-xl font-bold text-gray-900">Rebuts</h2>
            <div className="grid grid-cols-2 gap-4">
            {pauseReasons
              .filter((value) => value.type === TYPE_SCRAP)
              .map((pauseReason) => (
                <PauseReasonRadio
                  key={pauseReason.id}
                  pauseReason={pauseReason}
                />
              ))}
            </div>
          </div>
        </div>
      </RadioGroup>
    )}
  />
);

const PauseReasonRadio = ({ pauseReason }) => (
  <RadioGroup.Option
    key={pauseReason.id}
    value={pauseReason.id}
    className={({ active, checked }) =>
      classNames(
        active ? "border-trames-orange" : "border-gray-200",
        checked
          ? "border-trames-orange bg-trames-orange bg-opacity-10"
          : "bg-white",
        "border-1 flex cursor-pointer rounded-2xl border p-5"
      )
    }
  >
    {({ checked }) => (
      <>
        <div className="relative flex w-full items-center justify-between">
          <div className="flex items-center">
            <div className="text-sm">
              <RadioGroup.Label
                as="p"
                className={`text-lg  ${
                  checked ? "text-trames-orange" : "text-gray-900"
                }`}
              >
                {pauseReason.label}
              </RadioGroup.Label>
            </div>
          </div>
          {checked && (
            <div className="absolute right-0 text-trames-orange">
              <CheckCircleIcon className="h-10 w-10" />
            </div>
          )}
        </div>
      </>
    )}
  </RadioGroup.Option>
);
