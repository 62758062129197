import { ProductionControl } from "../components/production/dashboard/ProductionControl";
import { ProductionDashboard } from "../components/production/dashboard/ProductionDashboard";
import { GET, useApi } from "../hook/useApi";
import { useContext, useEffect } from "react";
import { eventContext, PAUSE } from "../context/eventContext";
import { Pause } from "../components/production/pause/Pause";
import { ProductionDashboardSkeleton } from "../components/production/dashboard/ProductionDashboardSkeleton";

export default function Home() {
  const { loading, data, error, callFetch } = useApi("production-event-logs");
  const {
    loading: loadingPauseReasons,
    data: dataPauseReasons,
    error: errorPauseReasons,
    callFetch: callFetchPauseReasons,
  } = useApi("production-pause-reasons");
  const {
    loading: loadingInstruction,
    data: dataInstruction,
    error: errorInstruction,
    callFetch: callFetchInstruction,
  } = useApi("production-instructions");
  const {
    setEvents,
    setFirstPlay,
    events,
    setPauseReasons,
    setInstruction,
    active,
  } = useContext(eventContext);

  useEffect(() => {
    callFetch({ method: GET });
    callFetchPauseReasons({ method: GET });
    callFetchInstruction({ method: GET });
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      setEvents(data);
      setFirstPlay(data[0].createdAt);
    }
  }, [data]);

  useEffect(() => {
    if (dataPauseReasons && dataPauseReasons.length > 0) {
      setPauseReasons(dataPauseReasons);
    }
  }, [dataPauseReasons]);

  useEffect(() => {
    if (dataInstruction && dataInstruction.duration) {
      setInstruction(dataInstruction);
    }
  }, [dataInstruction]);

  return (
    <>
      {!loading &&
      data &&
      !loadingPauseReasons &&
      dataPauseReasons &&
      !loadingInstruction &&
      dataInstruction &&
      dataInstruction.duration ? (
        <>{active === PAUSE ? <Pause /> : <ProductionDashboard />}</>
      ) : (
        <ProductionDashboardSkeleton />
      )}
    </>
  );
}
