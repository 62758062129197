import React from "react";
import { PRODUCTION } from "../../../../context/eventContext";
import { formatSecondDurationToTimeString } from "../../../../utils/misc";
import { ClockIcon } from "@heroicons/react/outline";

export const ProductionLine = ({ event, index, events }) => {
  return (
    <div className="flex">
      <div className="text-gray-600">
        {new Date(event.createdAt).toLocaleTimeString()}
      </div>
      <div className="flex-1 px-4 font-bold">
        Production #{events.filter((e) => e.type === PRODUCTION).length - index}
      </div>
      <div className="badge badge-primary">
        <ClockIcon className="font-white mr-1 h-4 w-4" />
        {formatSecondDurationToTimeString(event.duration)}
      </div>
    </div>
  );
};
