import React, { useContext, useEffect, useState } from 'react'
import { ProductionCounter } from '../production-counter/ProductionCounter'
import {
  eventContext,
  PAUSE,
  PLAY,
  PRODUCTION,
  STOP,
} from '../../../context/eventContext'
import { PauseIcon, PlayIcon, StopIcon } from '@heroicons/react/solid'
import { classNames } from '../../../utils/misc'

export const ProductionControl = () => {
  const [lock, setLock] = useState(true)
  const [counter, setCounter] = useState(0)
  const [play, setPlay] = useState(false)
  const { firstPlay, addEvent, events, active, setActive } =
    useContext(eventContext)

  useEffect(() => {
    if (active) {
      setPlay(active === PLAY)
    }
  }, [active])

  const handlePlay = () => {
    setPlay(true)

    if (active !== PAUSE && active !== STOP) {
      let date = Date.now()
      addEvent({ type: PLAY, createdAt: date })
    }

    setActive(PLAY)
  }

  const handlePause = () => {
    setPlay(false)
    setActive(PAUSE)
  }

  const handleStop = () => {
    setPlay(false)
    addEvent({ type: STOP, createdAt: Date.now() })
  }

  // send event production event
  useEffect(() => {
    if (counter !== 0) {
      let date = Date.now()
      let lastEvent = events.slice(-1)[0]
      let diff =
        new Date(date).getTime() - new Date(lastEvent.createdAt).getTime()
      if (diff >= 1000) {
        //lock in first time when play
        addEvent({ type: PRODUCTION, createdAt: Date.now() })
      }
    }
  }, [counter])

  return (
    <div className="flex flex-col">
      <div>
        <ProductionCounter
          setCounter={setCounter}
          setLock={setLock}
          play={play}
        />
      </div>
      <div
        className="flex flex-row items-center space-x-8 bg-white px-4 xl:justify-between xl:rounded-2xl flex-1">
        <div className="flex w-full items-center justify-around">
          <button
            type="button"
            disabled={lock || active === PLAY}
            className={classNames(
              'inline-flex items-center rounded-full',
              active === PLAY
                ? 'text-lime-900'
                : 'text-trames-green text-opacity-70 hover:text-lime-900',
            )}
            onClick={handlePlay}
          >
            <PlayIcon
              className="h-32 w-32 lg:h-24 lg:w-24 xl:h-24 xl:w-24"
              aria-hidden="true"
            />
          </button>
          <button
            type="button"
            disabled={
              lock || !firstPlay || active === PAUSE || active === STOP
            }
            className={classNames(
              'inline-flex items-center rounded-full',
              active === PAUSE
                ? 'text-trames-orange'
                : 'text-trames-green text-opacity-70 hover:text-trames-orange hover:text-opacity-100',
            )}
            onClick={handlePause}
          >
            <PauseIcon
              className="h-32 w-32 lg:h-24 lg:w-24 xl:h-24 xl:w-24"
              aria-hidden="true"
            />
          </button>
          <button
            type="button"
            disabled={lock || !firstPlay || active === STOP}
            className={classNames(
              'inline-flex items-center rounded-full ',
              active === STOP
                ? 'text-trames-red'
                : 'text-trames-green text-opacity-70 hover:text-trames-red hover:text-opacity-100',
            )}
            onClick={handleStop}
          >
            <StopIcon
              className="h-32 w-32 lg:h-24 lg:w-24 xl:h-24 xl:w-24"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>
  )
}
