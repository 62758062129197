export const CounterSkeleton = ({ label }) => {
  return (
    <div className="absolute top-1/2 flex h-full h-96 w-full -translate-y-44/100 transform flex-col items-center justify-center">
      <svg
        className="text-center"
        strokeWidth="20"
        fill="transparent"
        width="100%"
        height="100%"
        viewBox="0 0 400 400"
      >
        <path
          strokeLinecap="round"
          stroke="currentColor"
          className="text-white opacity-30"
          d="M 100 200 A 100 100 0 0 1 300 200"
        />
      </svg>
      <div className="absolute mt-8 flex flex-col items-center text-sm font-bold text-white sm:text-xl md:text-2xl lg:text-4xl">
        <span className="block h-8 w-24 rounded bg-white bg-opacity-30" />
        {label}
      </div>
    </div>
  );
};
