import React, { useEffect, useRef, useState } from "react";
import ProductionCounterWorker from "./productioncounter.worker";

const delay = 500;
const videoWidth = 600;
const videoHeight = 400;
const facingMode = "user";
const waitBetweenEachRecord = 10; // seconds number between each records

export const ProductionCounter = ({
  setCounter: setCounterParent,
  setLock,
  play: playParent,
}) => {
  const videoElement = useRef(null);
  const canvasElement = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [counter, setCounter] = useState(0);
  const [firstFrame, setFirstFrame] = useState(null);
  const [currentFrame, setCurrentFrame] = useState(null);
  const [lockBetweenEachRecord, setLockBetweenEachRecord] = useState(false);
  const workerRef = useRef();
  const timeOutRef = useRef();
  const waitBetweenEachRecordTimerRef = useRef();
  const loadedRef = useRef(loaded);
  const counterRef = useRef(counter);
  const firstFrameRef = useRef(firstFrame);
  const currentFrameRef = useRef(currentFrame);
  const playRef = useRef(playParent);
  const lockBetweenEachRecordRef = useRef(lockBetweenEachRecord);

  useEffect(() => {
    loadedRef.current = loaded;
    if (loadedRef.current) {
      setLock(false);
    }
  }, [loaded]);

  useEffect(() => {
    firstFrameRef.current = firstFrame;
  }, [firstFrame]);

  useEffect(() => {
    currentFrameRef.current = currentFrame;
  }, [currentFrame]);

  useEffect(() => {
    counterRef.current = counter;
    setCounterParent(counterRef.current);
  }, [counter]);

  useEffect(() => {
    playRef.current = playParent;
  }, [playParent]);

  useEffect(() => {
    lockBetweenEachRecordRef.current = lockBetweenEachRecord;
  }, [lockBetweenEachRecord]);

  useEffect(() => {
    workerRef.current = new ProductionCounterWorker();
    const handleWorkerMessage = (e) => {
      if (e.data === "loaded") {
        setLoaded(true);
      }

      if(e.data.record && e.data.record.imData){
        var ctx = canvasElement.current.getContext("2d");
        ctx.putImageData(e.data.record.imData, 0, 0);
      }

      if (e.data.record && e.data.record.record && !lockBetweenEachRecordRef.current) {
        setCounter(counterRef.current + 1);
        setLockBetweenEachRecord(true);
        waitBetweenEachRecordTimerRef.current = setTimeout(
          () => setLockBetweenEachRecord(false),
          waitBetweenEachRecord * 1000
        );
      }

      setFirstFrame(currentFrameRef.current);
      timeOutRef.current = setTimeout(processImage, delay);
    };

    if (workerRef.current) {
      workerRef.current.onmessage = handleWorkerMessage;
      initVideo();
    }

    return () => {
      workerRef.current.terminate();
      workerRef.current = null;
      clearTimeout(timeOutRef.current);
      clearTimeout(waitBetweenEachRecordTimerRef.current);
    };
  }, []);

  const initVideo = () => {
    async function initCamera() {
      videoElement.current.width = videoWidth;
      videoElement.current.height = videoHeight;

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: facingMode,
          },
        });
        videoElement.current.srcObject = stream;

        return new Promise((resolve) => {
          videoElement.current.onloadedmetadata = () => {
            resolve(videoElement.current);
          };
        });
      }
      const errorMessage =
        "This browser does not support video capture, or this device does not have a camera";
      alert(errorMessage);
      return Promise.reject(errorMessage);
    }

    async function load() {
      const videoLoaded = await initCamera();
      videoLoaded.play();
      processImage();

      return videoLoaded;
    }

    load();
  };

  const processImage = () => {
    canvasElement.current.width = videoWidth;
    canvasElement.current.height = videoHeight;
    var ctx = canvasElement.current.getContext("2d");
    ctx.clearRect(0, 0, videoWidth, videoHeight);
    ctx.drawImage(videoElement.current, 0, 0, videoWidth, videoHeight);

    setCurrentFrame(ctx.getImageData(0, 0, videoWidth, videoHeight));
    workerRef.current.postMessage({
      imageData: ctx.getImageData(0, 0, videoWidth, videoHeight),
      firstFrame: firstFrameRef.current,
      loaded: loadedRef.current,
      play: playRef.current,
    });
  };

  return (
    <>
      <video
        className="video h-full w-full -scale-x-100 rounded-t-2xl"
        playsInline
        ref={videoElement}
      />
      <canvas ref={canvasElement} className={process.env.NEXT_PUBLIC_DETECTION_MODE === 'dev' ? '' : 'hidden'} />
    </>
  );
};
