import React, { useEffect, useState } from "react";
import { formatSecondDurationToTimeString } from "../../../../utils/misc";

export const CycleCounter = ({ events, instruction }) => {
  const [min, setMin] = useState(null);
  const [avg, setAvg] = useState(null);

  useEffect(() => {
    if (events.length > 0) {
      let eventsSortable = events.sort((a, b) => a.duration - b.duration);
      setMin(eventsSortable[0].duration);
      let totalDuration = 0;
      eventsSortable.forEach((event) => {
        totalDuration += event.duration;
      });

      if (totalDuration !== 0) {
        setAvg((totalDuration / eventsSortable.length).toFixed(0));
      }
    }
  }, [JSON.stringify(events)]);

  return (
    <div className="flex-row space-x-4">
      {min && (
        <span className="badge badge-primary-invert text-xs">
          Minimum : {formatSecondDurationToTimeString(min)}
        </span>
      )}
      {avg && (
        <span className="badge badge-primary-invert text-xs">
          Moyenne : {formatSecondDurationToTimeString(avg)}
        </span>
      )}
      <span className="badge badge-success-invert text-xs">
        Objectif : {formatSecondDurationToTimeString(instruction.cycleDuration)}
      </span>
    </div>
  );
};
