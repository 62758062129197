import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { modalContext } from '../../../context/modalContext';
import { eventContext, PRODUCTION } from '../../../context/eventContext';
import { DELETE, useApi } from '../../../hook/useApi';

export const ContentCancelProduction = () => {
  const { setOpen } = useContext(modalContext);
  const { setEvents, events } = useContext(eventContext);
  const {
    error: errorRemove,
    callFetch: callFetchRemove,
    loading: loadingRemove,
    data: dataRemove
  } = useApi('production-event-logs');

  useEffect(() => {
    if (dataRemove?.id && !loadingRemove && !errorRemove) {
      let _events = events;
      let index = _events.findIndex(event => event.id === dataRemove.id);
      _events.splice(index, 1);
      setEvents([..._events]);
      setOpen(false);
    }
  }, [loadingRemove, setEvents, errorRemove, dataRemove]);

  const handleRemove = () => {
    let index = null;
    for (let i = events.length - 1; i >= 0; i--) {
      if (events[i].type === PRODUCTION) {
        index = i;
        break;
      }
    }

    if (index !== null) {
      callFetchRemove({
        method: DELETE,
        id: events[index].id
      });
    } else {
      setOpen(false);
    }
  };
  return (
    <>
      <div className="sm:flex sm:items-start">
        <div
          className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title as="h3" className="box-title leading-6 font-medium text-gray-900">
            Suppression production
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Êtes-vous sûr de vouloir supprimer la dernière ligne de production réalisée ?
              <br/> <strong>Attention</strong> : cette action est irréversible.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          disabled={loadingRemove}
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleRemove}
        >
          {loadingRemove && (
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
              <path className="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>
          )}

          Supprimer
        </button>
        <button
          disabled={loadingRemove}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-trames-orange sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Annuler
        </button>
      </div>
    </>
  );
};