import React, { useEffect, useState } from "react";
import { formatSecondDurationToTimeString } from "../../../../utils/misc";
import { Counter } from "./Counter";

export const DurationCounter = ({ instruction, indicators, firstPlay }) => {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (indicators.trs !== 0) {
      let current = 0;
      if (firstPlay) {
        current = ((new Date() - new Date(firstPlay)) / 1000).toFixed(0);
      }
      setCurrentTime(current);
    }
  }, [indicators.trs]);

  return (
    <Counter
      percentage={(currentTime / instruction.duration) * 100}
      label={
        <div className="mt-6 text-center">
          {formatSecondDurationToTimeString(currentTime, false)}/
          {formatSecondDurationToTimeString(instruction.duration, false)}
          <br />
          Durée OF min
        </div>
      }
    />
  );
};
