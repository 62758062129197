import React from "react";
import { formatSecondDurationToTimeString } from "../../../../utils/misc";

export const PauseProgress = ({ events, pauseReasons }) => {
  let totalDuration = 0;
  let pauseReasonsGroup = {};
  events.forEach((event) => {
    if (event.properties && event.properties.productionPauseReasons) {
      event.properties.productionPauseReasons.forEach((pause) => {
        totalDuration += event.duration;
        if (Object.keys(pauseReasonsGroup).includes(pause)) {
          pauseReasonsGroup[pause].number = pauseReasonsGroup[pause].number + 1;
          pauseReasonsGroup[pause].duration =
            pauseReasonsGroup[pause].duration + event.duration;
        } else {
          pauseReasonsGroup[pause] = { number: 1, duration: event.duration };
        }
      });
    }
  });

  let sortablePauseReasonsGroup = [];

  for (let pauseGroup in pauseReasonsGroup) {
    sortablePauseReasonsGroup.push([pauseGroup, pauseReasonsGroup[pauseGroup]]);
  }

  sortablePauseReasonsGroup.sort((a, b) => b[1].duration - a[1].duration);

  return (
    <div className="space-y-5">
      {sortablePauseReasonsGroup.map((sortablePauseReason, index) => {
        let time = formatSecondDurationToTimeString(
          sortablePauseReason[1].duration
        );
        let percentage = (
          (sortablePauseReason[1].duration / totalDuration) *
          100
        ).toFixed(1);
        return (
          <div key={index}>
            <div>
              <div className="text-sm font-bold">
                {
                  pauseReasons.find(
                    (pauseReasonObject) =>
                      parseInt(pauseReasonObject.id, 10) ===
                      parseInt(sortablePauseReason[0], 10)
                  ).label
                }
              </div>
              <div className="my-1 overflow-hidden rounded-full bg-gray-200">
                <div
                  className="h-2 rounded-full bg-trames-orange"
                  style={{ width: `${percentage}%` }}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 text-xs">
              <span>{time}</span>
              <span className="text-right">{percentage}%</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
