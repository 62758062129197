import React from "react";
import { Counter } from "./Counter";

export const TrsCounter = ({ indicators, instruction }) => (
  <Counter
    percentage={indicators.trs}
    label={
      <div className="mt-6 text-center">
        {indicators.trs.toFixed(0)} %<br />
        TRS
      </div>
    }
    time={false}
  />
);
